import React from 'react';
import cn from 'classnames';
import { joinValues } from '../../utils/helpers';

const colors = {
  primary: 'bg-primary',
  secondary: 'bg-secondary',
  white: 'bg-white',
  black: 'bg-off-black',
};

type Color = keyof typeof colors;

const sizes = {
  sm: {
    width: 'w-5',
    height: 'h-0.5',
  },
  md: {
    width: 'w-7',
    height: 'h-[0.1875rem]',
  },
  lg: {
    width: 'w-9',
    height: 'h-[0.1875rem]',
  },
};

type Size = keyof typeof sizes;

type HamburgerButtonProps = {
  className?: string;
  size?: Size;
  color?: Color;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
};

function HamburgerButton({
  className,
  isOpen,
  setIsOpen,
  color = 'secondary',
  size = 'md',
}: HamburgerButtonProps) {
  const stroke = sizes[size];

  const frameSize = cn(
    size === 'sm' && 'w-5 h-3.5',
    size === 'md' && 'w-7 h-[1.3125rem]',
    size === 'lg' && 'w-9 h-[1.4375rem]',
  );

  return (
    <button
      aria-label="menu_button"
      type="button"
      className={joinValues({
        base: cn(className, 'group flex items-center justify-center text-black focus:outline-none'),
        size: frameSize,
      })}
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className={cn('relative', frameSize)}>
        {/* Stroke 1 */}
        <span
          className={joinValues({
            base: 'absolute left-0 transform hamburger-outer',
            size: joinValues(stroke),
            color: colors[color],
            status: isOpen && cn(
              'rotate-45 group-hover:delay-200',
              size === 'sm' && 'translate-y-1.5',
              size === 'md' && 'translate-y-[0.5625rem]',
              size === 'lg' && 'translate-y-2.5',
            ),
          })}
        />
        {/* Stroke 2 */}
        <span
          className={joinValues({
            base: 'absolute left-0 transform hamburger-middle',
            color: colors[color],
            size: cn(
              size === 'sm' && 'translate-y-1.5',
              size === 'md' && 'translate-y-[0.5625rem]',
              size === 'lg' && 'translate-y-2.5',
            ),
            status: cn(isOpen
              ? cn('w-0', stroke.height)
              : joinValues({ ...stroke, base: 'hamburger-middle-delay', opacity: 'opacity-100' }),
            ),
          })}
        />
        {/* Stroke 3 */}
        <span
          className={joinValues({
            base: 'absolute left-0 transform hamburger-outer',
            size: joinValues(stroke),
            color: colors[color],
            status: cn(
              isOpen
                ? cn(
                  '-rotate-45 group-hover:delay-200',
                  size === 'sm' && 'translate-y-1.5',
                  size === 'md' && 'translate-y-[0.5625rem]',
                  size === 'lg' && 'translate-y-2.5',
                )
                : cn(
                  size === 'sm' && 'translate-y-3',
                  size === 'md' && 'translate-y-[1.125rem]',
                  size === 'lg' && 'translate-y-5',
                )),
          })}
        />
      </div>
    </button>
  );
}

export default HamburgerButton;
