import React, { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { isKeyOf } from '../../utils/predicates';

const sizes = {
  h1: 'text-2xl lg:text-5xl lg:leading-[4rem]',
  h2: 'text-[2rem] leading-10 lg:text-5xl lg:leading-[4rem]',
  h3: 'text-2xl lg:text-[2rem] lg:leading-10',
  h4: 'text-xl leading-6 lg:text-2xl',
  h5: 'text-base leading-[1.375rem] lg:leading-6',
  custom: '',
  sm: 'text-sm',
  md: 'text-base',
  lg: 'text-lg',
  xl: 'text-xl',
};

const weights = {
  normal: 'font-normal',
  light: 'font-light',
  semibold: 'font-semibold',
  bold: 'font-bold',
};

const colors = {
  secondary: 'text-secondary',
  blue: 'text-blue-dark',
  white: 'text-white',
  black: 'text-off-black',
  primary: 'text-primary',
  gray: 'text-gray-light',
};

export type HeaderColor = keyof typeof colors;

export type HeaderSize = keyof typeof sizes;

export type HeaderWeight = keyof typeof weights;

type HeaderProps = {
  element: React.ElementType;
  values?: Record<string, string>;
  className?: string;
  langKey?: string;
  color?: HeaderColor;
  size?: HeaderSize;
  weight?: HeaderWeight;
  children?: ReactNode;
};

function Header({
  langKey,
  element,
  size,
  className,
  values,
  children,
  weight = 'bold',
  color = 'secondary',
}: HeaderProps) {
  const key = size ?? element;
  const Element = element;

  const { t } = useTranslation();
  const text = langKey && t(langKey, values);

  const options = cn(
    'whitespace-pre-line',
    isKeyOf(sizes, key) ? sizes[key] : '',
    colors[color],
    className,
    weights[weight],
    'font-[Campton]',
  );

  return (
    <Element className={options}>
      {children ?? text}
    </Element>
  );
}

export default Header;
