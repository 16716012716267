import React, { type FC, type ReactNode, type SVGAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { motion } from 'framer-motion';
import {
  type AccountDetails,
  type BankCode,
  bankCodeToBankName,
} from '../../services/pbl-api';
import { toDateFormat, transition } from '../../utils/helpers';
import SubmitButton from '../../components/container/SubmitButton';
import Divider from '../../components/ui/Divider';
import Text from '../../components/ui/Text';
import AbnIcon from '../../public/icons/bank/abn_sm.svg';
import AsnIcon from '../../public/icons/bank/asn_sm.svg';
import IngIcon from '../../public/icons/bank/ing_sm.svg';
import KnabIcon from '../../public/icons/bank/knab_sm.svg';
import RabobankIcon from '../../public/icons/bank/rabobank_sm.svg';
import RegiobankIcon from '../../public/icons/bank/regiobank_sm.svg';
import SnsIcon from '../../public/icons/bank/sns_sm.svg';
import TriodosIcon from '../../public/icons/bank/triodos_sm.svg';
import WarningIcon from '../../public/icons/warning_circle.svg';
import BankAccount from './BankAccount';

const DAY_IN_MS = 1000 * 60 * 60 * 24;

const component = 'account_card';

type ConsentDateProps = {
  className?: string;
  isWarning: boolean;
  children: ReactNode;
};

function ConsentDate({ className, children, isWarning }: ConsentDateProps) {
  return (
    <Text
      variant="pill"
      size="xs"
      className={className}
      weight={isWarning ? 'semibold' : 'normal'}
      color={isWarning ? 'error' : 'black'}
    >
      {children}
    </Text>
  );
}

type IconMapper = {
  [key in BankCode]: FC<SVGAttributes<SVGElement>>
};

const iconMapper: Partial<IconMapper> = {
  RABOBANK: RabobankIcon,
  ING: IngIcon,
  ABN: AbnIcon,
  KNAB: KnabIcon,
  ASN: AsnIcon,
  SNS: SnsIcon,
  REGIOBANK: RegiobankIcon,
  TRIODOS: TriodosIcon,
};

const getRemainingDays = (date: string) => {
  const currentDate = new Date();
  const expiryDate = new Date(date);

  const differenceInTicks = expiryDate.getTime() - currentDate.getTime();
  return Math.floor(differenceInTicks / DAY_IN_MS);
};

type BankCardProps = {
  needReconsent: boolean;
  isLoading: boolean;
  expireAt: string;
  errorKey?: string;
  accounts: AccountDetails[];
  bankName: BankCode;
  onClick: () => void;
  connectionId: string;
};

function BankCard({
  accounts,
  bankName,
  onClick,
  isLoading,
  expireAt,
  errorKey,
  needReconsent,
  connectionId,
}: BankCardProps) {
  const remainingDays = getRemainingDays(expireAt);
  const { t } = useTranslation();

  const isExpired = remainingDays < 0;
  const isAlmostExpired = remainingDays >= 0 && remainingDays < 10;

  const Icon = iconMapper[bankName];
  const name = bankCodeToBankName[bankName];

  const buttonLabel = t('text_65b9af88774e154fb120cf8d');
  const isWarning = isExpired || isAlmostExpired;

  const formatExpiryDate = () => {
    const expiryDate = new Date(expireAt);
    const date = toDateFormat(expiryDate);

    if (isExpired) {
      return t('text_65b9af88774e154fb120cf7a');
    }

    if (isAlmostExpired) {
      return t('text_65b9af88774e154fb120cf84', { number: remainingDays });
    }

    return t('text_65b9af88774e154fb120cf8e', { date });
  };

  return (
    <motion.div
      layoutId={`${component}_${bankName}_${connectionId}`}
      transition={transition}
      className={cn(
        'flex flex-col border rounded overflow-clip',
        needReconsent ? 'border-gray-dark' : 'border-green-dark',
      )}
    >
      {isWarning && (
        <motion.div
          className="hidden items-center justify-center w-full py-2.5 gap-2 bg-gray-background lg:flex"
          layout="position"
        >
          <WarningIcon className="w-5" />
          <Text
            size="xs"
            weight="semibold"
            color="error"
          >
            {formatExpiryDate()}
          </Text>
        </motion.div>
      )}

      <div className="flex flex-col items-stretch p-6 gap-6">
        <div className="flex items-center justify-between gap-4">
          <motion.div
            className="flex flex-row gap-4 lg:gap-6"
            layout="position"
            transition={transition}
          >
            {Icon && <Icon className="w-8" />}
            <Text className="hidden flex-1 lg:flex">{name}</Text>
          </motion.div>

          <div className="flex flex-1 justify-end">
            {needReconsent ? (
              <SubmitButton
                label={buttonLabel}
                isLoading={isLoading}
                name={`reconsent_lg_${bankName}_${connectionId}`}
                className="hidden lg:flex"
                error={errorKey}
                onClick={onClick}
              />
            ) : (
              <ConsentDate
                className="hidden lg:flex"
                isWarning={isWarning}
              >
                {formatExpiryDate()}
              </ConsentDate>
            )}

            <ConsentDate
              className="flex lg:hidden"
              isWarning={isWarning}
            >
              {formatExpiryDate()}
            </ConsentDate>
          </div>
        </div>

        <Divider color="natural" className="lg:px-6" />

        <BankAccount accounts={accounts} />

        {needReconsent && (
          <SubmitButton
            label={buttonLabel}
            isLoading={isLoading}
            name={`reconsent_sm_${bankName}`}
            className="flex ml-auto lg:hidden"
            error={errorKey}
            onClick={onClick}
          />
        )}
      </div>
    </motion.div>
  );
}

export default BankCard;
