import React from 'react';
import cn from 'classnames';
import { motion } from 'framer-motion';
import { transition } from '../../utils/helpers';

const colors = {
  base: 'border-gray-medium',
  natural: 'border-gray-natural',
  dark: 'border-gray-[#c4c4c4]',
  secondary: 'border-secondary',
};

type DividerProps = {
  color?: keyof typeof colors;
  name?: string;
  className?: string;
};

function Divider({ color = 'base', className, name }: DividerProps) {
  const layoutId = name && `${name}_divider`;

  return (
    <motion.div
      className={cn(className, colors[color], 'border-t border-solid')}
      layoutId={layoutId}
      layout="position"
      transition={transition}
    />
  );
}

export default Divider;
