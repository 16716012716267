import React, { Fragment } from 'react';
import cn from 'classnames';
import { motion } from 'framer-motion';
import type { AccountDetails } from '../../services/pbl-api';
import { formatIban, transition } from '../../utils/helpers';
import Divider from '../../components/ui/Divider';
import Text from '../../components/ui/Text';

type RowProps = {
  label: string;
  value: string;
  className?: string;
};

function Row({ className, label, value }: RowProps) {
  return (
    <div className={cn('flex flex-col truncate', className)}>
      <Text
        langKey={label}
        color="gray"
      />
      <Text
        className="truncate"
        color="black"
        weight="normal"
      >
        {value}
      </Text>
    </div>
  );
}

type BankAccountProps = {
  accounts: AccountDetails[];
};

function BankAccount({ accounts }: BankAccountProps) {
  return (
    <div className="flex flex-col gap-4 lg:gap-6">
      {accounts.map((account, index) => {
        const isLastAccount = index === accounts.length - 1;
        const { iban, clientName } = account;

        return (
          <Fragment key={iban}>
            <motion.div
              className="flex flex-col gap-2 justify-between lg:flex-row lg:gap-6"
              layout="position"
              transition={transition}
            >
              <Row
                label="text_65b9af88774e154fb120cf8f"
                value={clientName}
                className="flex-shrink-0"
              />
              <Row
                label="text_65b9af88774e154fb120cf91"
                value={formatIban({ iban, isMasked: true })}
              />
            </motion.div>

            {!isLastAccount && <Divider color="dark" className="lg:px-6" />}
          </Fragment>
        );
      })}
    </div>
  );
}
export default BankAccount;
