import { featureFlags } from '../utils/constants';
import useSplit from './useSplit';

const useNewStartFlow = () => {
  const { newStartFlow } = featureFlags;
  const { isReady, treatment } = useSplit(newStartFlow);

  return {
    isReady,
    treatment,
  };
};

export default useNewStartFlow;
