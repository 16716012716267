import { delay, http, HttpResponse } from 'msw';
import authentication from './fixtures/authentication';
import bankConnections, { createDate } from './fixtures/bankConnections.ts';
import companies from './fixtures/companyDetails';
import eligibility from './fixtures/eligibility';
import loanGoal from './fixtures/loanGoal';
import offers from './fixtures/offers';
import { connections, customerId, status, transactionInfo } from './fixtures/saltedge.ts';

const apiUrl = process.env.REACT_APP_API_URL;

const FIVE_DAYS = 5;

let reconsentConnectionId;

let reconsentCount = 0;

const RECONSENT_TRIES = 2;

let caseStateCount = 0;

// authentication token
const getJwtToken = http.post(`${apiUrl}/dls-authentication/v3/authentication/pbl/anonymous-tokens`, () => (
  HttpResponse.json(authentication, { status: 200 })
));

// case state
const getCaseState = http.get(`${apiUrl}/dls-pbl-api/v3/case-state`, () => {
  const state = {
    offering: 'PBL',
    origin: 'DLS',
    journeyBlocks: {
      TRANSACTION_ANALYSIS: caseStateCount >= 3 ? 'SUCCESS' : 'PENDING',
      SCORECARD_RESULT: caseStateCount >= 6 ? 'SUCCESS' : 'PENDING',
      OFFERS_AVAILABLE: caseStateCount >= 9 ? 'SUCCESS' : 'PENDING',
    },
  };

  caseStateCount += 1;

  return HttpResponse.json(state, { status: 200 });
});

// company check
const companySearch = http.get(`${apiUrl}/dls-company-search/v1/company/search`, ({ request }) => {
  const url = new URL(request.url);
  const search = url.searchParams.get('query');

  const requester = companies.filter((company) => company.name.toLowerCase().includes(search));

  const companyDetails = requester.length === 0
    ? companies
    : requester;

  return HttpResponse.json(companyDetails, { status: 200 });
});

// loan goal
const setLoanGoal = http.post(`${apiUrl}/dls-pbl-api/v3/loan-goal`, () => (
  HttpResponse.json(loanGoal, { status: 202 })
));

// requester eligibility
const requesterEligibility = http.post(`${apiUrl}/dls-pbl-api/v3/requester-eligibility`, () => (
  HttpResponse.json(eligibility, { status: 200 })
));

// contact details
const requestContact = http.post(`${apiUrl}/dls-contact-details/v1/request-contact`, () => (
  new HttpResponse(null, { status: 204 })
));

// loan amount
const setLoanAmount = http.post(`${apiUrl}/dls-pbl-api/v3/loan-amount`, () => (
  new HttpResponse(null, { status: 204 })
));

// affordability
const expectedExpenses = http.post(`${apiUrl}/dls-pbl-api/v3/expected-expenses`, () => (
  new HttpResponse(null, { status: 202 })
));

// bank accounts
const getBankAccounts = http.get(`${apiUrl}/dls-pbl-api/v3/fetch-accounts`, async () => {
  const isReconsent = reconsentConnectionId != null;

  await delay(isReconsent ? 0 : 2000);
  const connectionDetails = bankConnections;

  if (reconsentConnectionId && reconsentCount >= RECONSENT_TRIES) {
    const details = connectionDetails.find((item) => item.connectionId === reconsentConnectionId);

    const date = createDate('future');
    details.consentExpiresAt = date;

    details.needReconsent = false;
    reconsentConnectionId = undefined;
  }

  if (isReconsent) {
    reconsentCount += 1;
  }

  return HttpResponse.json({ connectionDetails }, { status: 200 });
});

// transaction consent
const consent = http.post(`${apiUrl}/dls-transaction-processing/v1/financials/consent`, () => (
  new HttpResponse(null, { status: 204 })
));

// create psd2 session
const createSession = http.post(`${apiUrl}/dls-psd2-aggregator/v1/create/session`, async ({ request }) => {
  const data = await request.json();
  const { returnUrl } = data;

  const date = new Date();
  date.setDate(date.getDate() + FIVE_DAYS);

  const session = {
    connectUrl: returnUrl,
    expiresAt: date,
    customer: customerId,
  };

  await delay(2000);

  return HttpResponse.json(session, { status: 200 });
});

// connection id
const getConnections = http.get(`${apiUrl}/dls-psd2-aggregator/v1/fetch/connections`, () => (
  HttpResponse.json({ connections }, { status: 200 })
));

// magic link resume
const getResumeToken = http.post(`${apiUrl}/dls-authentication/v2/case/:id/authentication/resume-tokens`, () => (
  HttpResponse.json(authentication, { status: 200 })
));

// psd2 connection
const createConnection = http.get(`${apiUrl}/dls-psd2-aggregator/v1/fetch/connection/:id`, async () => {
  await delay(2000);

  return HttpResponse.json({ finished: true }, { status: 200 });
});

// psd2 transactions
const getTransactions = http.post(`${apiUrl}/dls-pbl-api/v3/transactions/saltedge/fetch-transactions`, () => (
  HttpResponse.json(transactionInfo, { status: 200 })
));

// psd2 status
const getTransactionStatus = http.get(`${apiUrl}/dls-transaction-processing/v1/financials/psd2/status`, () => (
  HttpResponse.json(status, { status: 200 })
));

// confirm transactions
const confirmTransactionProcess = http.post(`${apiUrl}/dls-transaction-processing/v3/financials/confirm`, () => (
  new HttpResponse(null, { status: 204 })
));

// offers
const getOffers = http.get(`${apiUrl}/dls-pbl-api/v3/offers`, async () => {
  await delay(2000);

  return HttpResponse.json(offers, { status: 200 });
});

// confirm offer
const selectOffer = http.post(`${apiUrl}/dls-offer/v3/termsheet/offers/select`, () => (
  new HttpResponse(null, { status: 204 })
));

// remove transactions
const removeAccount = http.delete(`${apiUrl}/dls-transaction-processing/v1/financials/remove-transactions`, () => (
  new HttpResponse(null, { status: 204 })
));

// contact form
const sendMessage = http.post(`${apiUrl}/dls-contact-details/v1/contact-form`, async () => {
  await delay(2000);

  return new HttpResponse(null, { status: 204 });
});

// feedback
const sendFeedback = http.post(`${apiUrl}/dls-pbl-api/v3/feedback`, () => (
  new HttpResponse(null, { status: 204 })
));

// reconsent
const reconnect = http.post(`${apiUrl}/dls-pbl-api/v3/transaction/psd2/reconnect`, async ({ request }) => {
  const data = await request.json();
  const { returnUrl, connectionId } = data;

  const date = new Date();
  date.setDate(date.getDate() + FIVE_DAYS);

  reconsentConnectionId = connectionId;
  reconsentCount = 0;

  const session = {
    connectUrl: returnUrl,
    expiresAt: date,
    customer: customerId,
  };

  await delay(2000);

  return HttpResponse.json(session, { status: 200 });
});

const handlers = [
  companySearch,
  getJwtToken,
  getCaseState,
  getResumeToken,
  requestContact,
  expectedExpenses,
  sendMessage,
  getOffers,
  selectOffer,
  requesterEligibility,
  setLoanGoal,
  setLoanAmount,
  createSession,
  getConnections,
  createConnection,
  getTransactions,
  consent,
  getTransactionStatus,
  getBankAccounts,
  removeAccount,
  confirmTransactionProcess,
  reconnect,
  sendFeedback,
];

export default handlers;
