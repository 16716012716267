import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import useHeight from '../../hooks/useHeight';
import useIsDesktop from '../../hooks/useIsDesktop';
import { joinValues, setCookieConsent, transition } from '../../utils/helpers';
import Button from '../ui/Button';
import Header from '../ui/Header';
import Text from '../ui/Text';

const component = 'cookie';

type OptionCardProps = {
  header: string;
  text: string;
  onClick: () => void;
  isSelected: boolean;
};

function OptionCard({ onClick, isSelected, header, text }: OptionCardProps) {
  return (
    <button
      className={joinValues({
        base: 'flex flex-col gap-2 p-4 text-left rounded',
        selected: isSelected && 'border-primary',
        border: 'border border-gray-dark',
        hover: 'hover:bg-slate-100',
        active: 'active:bg-slate-200',
        animation: 'transition-colors duration-200',
      })}
      type="button"
      onClick={onClick}
    >
      <Header
        langKey={header}
        size="h5"
        element="h2"
      />
      <Text
        langKey={text}
        size="sm"
      />
    </button>
  );
}

type CookieConsentProps = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  setHasUserSeenCookiesPopup: (value: boolean) => void;
};

function CookieConsent({ isOpen, setIsOpen, setHasUserSeenCookiesPopup }: CookieConsentProps) {
  const isDesktop = useIsDesktop();

  const { t } = useTranslation();
  const [ref, height] = useHeight();

  const [showOptions, setShowOptions] = useState(false);
  const [selected, setSelected] = useState<number>();

  const onSubmit = () => {
    setCookieConsent(true);
    setIsOpen(false);
    setHasUserSeenCookiesPopup(true);
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition,
          }}
          exit={{
            opacity: 0,
            transition: { duration: 0.3, ease: 'easeIn' },
          }}
          className="fixed inset-0 bg-black/60 z-40"
        >
          <motion.div
            initial={{ y: '100%' }}
            animate={{
              height,
              x: 0,
              y: 0,
              transition,
            }}
            exit={{
              transition: { duration: 0.3, ease: 'easeIn' },
              y: '100%',
            }}
            className={joinValues({
              position: 'fixed bottom-0',
              base: 'flex items-start w-full bg-white max-sm:max-h-[80%] shadow-rabobank-md',
              sm: 'sm:rounded',
              options: 'rounded-tl-2xl rounded-tr-2xl',
              scroll: 'overflow-hidden',
              lg: 'lg:max-w-xl lg:right-10 lg:bottom-10',
            })}
          >
            <div ref={ref} className="w-full p-6 pb-12 lg:pb-6">
              {showOptions ? (
                <div className="flex flex-col gap-4">
                  <OptionCard
                    header="text_65c16700242ca900c6c61fb3"
                    text="text_65c16700242ca900c6c61fb4"
                    onClick={() => setSelected(1)}
                    isSelected={selected === 1}
                  />
                  <OptionCard
                    header="text_65c16700242ca900c6c61fb5"
                    text="text_65c16700242ca900c6c61fb6"
                    onClick={() => setSelected(2)}
                    isSelected={selected === 2}
                  />
                  <Button
                    label={t(`${component}.save_button`)}
                    name="cookies_save"
                    color="primary"
                    className="w-full"
                    disabled={!selected}
                    onClick={onSubmit}
                  />
                </div>
              ) : (
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col items-center text-center lg:text-left lg:items-start">
                    <div className="flex gap-1">
                      <Text size="lg" className="-mt-3 lg:-mt-2">🍪</Text>
                      <Header
                        langKey="text_65c16700242ca900c6c61faf"
                        size="h4"
                        element="h1"
                      />
                    </div>
                    <Text langKey="cookie.text" />
                  </div>

                  <div className="flex flex-col w-full items-center gap-4 lg:flex-row lg:justify-end">
                    <Button
                      label={t('text_65c16700242ca900c6c61fb1')}
                      name="cookies_accept"
                      color="primary"
                      className="w-full md:w-fit"
                      onClick={onSubmit}
                    />
                    <Button
                      label={t('text_65c16700242ca900c6c61fb2')}
                      name="show_options"
                      color="secondary"
                      variant={isDesktop ? 'outline' : 'link'}
                      className={joinValues({
                        base: 'w-full underline text-off-black justify-center md:w-fit lg:text-secondary lg:no-underline',
                        md: 'md:w-fit',
                        lg: 'lg:text-secondary lg:no-underline',
                      })}
                      onClick={() => setShowOptions(true)}
                    />
                  </div>
                </div>
              )}
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default CookieConsent;
