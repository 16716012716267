import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import useHeight from '../../hooks/useHeight';
import useIsDesktop from '../../hooks/useIsDesktop';
import { measureLinkClick } from '../../utils/analytics';
import { joinValues, transition } from '../../utils/helpers';
import Button from '../ui/Button';
import Header from '../ui/Header';
import Text from '../ui/Text';
import Close from '../../public/icons/close.svg';
import DocumentIcon from '../../public/icons/documents.svg';

type CrossSellPopUpProps = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  hasUserSeenCookiesPopup: boolean;
};

function CrossSellPopUp({ isOpen, setIsOpen, hasUserSeenCookiesPopup }: CrossSellPopUpProps) {
  const { t } = useTranslation();
  const [ref, height] = useHeight();
  const isDesktop = useIsDesktop();
  const [isAnimating, setIsAnimating] = useState(isOpen);

  useEffect(() => {
    if (isOpen) {
      setIsAnimating(true);
    }
  }, [isOpen]);

  const handleMoreInfoClick = () => {
    measureLinkClick('cross sell popup link');
    window.open('https://factuurfinanciering.rabobank.nl/?utm_source=AB&utm_medium=PBLC_referral&utm_campaign=pop-up', '_blank');
  };

  const closeCrossSellPopUp = () => {
    setIsAnimating(false);
    measureLinkClick('cross sell popup close');
  };

  return (
    <AnimatePresence>
      {isAnimating && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition }}
          exit={{ opacity: 0, transition: { duration: 0.3, ease: 'easeIn' } }}
          className="fixed z-40"
          onAnimationComplete={() => {
            if (!isAnimating) {
              setIsOpen(false);
            }
          }}
        >
          <motion.div
            initial={{ y: '100%' }}
            animate={{
              height,
              x: 0,
              y: 0,
              transition,
            }}
            exit={{
              transition: { duration: 0.3, ease: 'easeIn' },
              y: '100%',
            }}
            className={joinValues({
              position: 'fixed bottom-0 z-40',
              base: 'flex items-start w-full lg:w-[400px] bg-white max-sm:max-h-[80%] shadow-rabobank-md',
              options: 'rounded-tl-2xl rounded-tr-2xl lg:rounded-lg',
              scroll: 'overflow-hidden',
              lg: 'lg:max-w-xl lg:right-10 lg:bottom-10',
            })}
          >
            {isDesktop ? (
              <div ref={ref} className="flex flex-col lg:w-[400px] p-6 gap-4">
                <div className="flex justify-between items-start">
                  <div className="flex gap-2 items-center">
                    <DocumentIcon className="w-9 h-9 shrink-0" />
                    <Header
                      langKey="text_66951de4510dd7006508cef2"
                      size="md"
                      element="h1"
                      className="leading-6 pt-1"
                    />
                  </div>
                  <Close
                    onClick={closeCrossSellPopUp}
                    className="shrink-0 cursor-pointer"
                  />
                </div>
                <Text langKey="text_66951de4510dd7006508cef7" />
                <Button
                  label={t('text_66951de4510dd7006508cefa')}
                  name="crossSellPopUp.show_options"
                  color="secondary"
                  variant="outline"
                  className="w-fit underline text-off-black justify-center lg:text-secondary lg:no-underline"
                  onClick={handleMoreInfoClick}
                />
              </div>
            ) : (
              !hasUserSeenCookiesPopup && (
                <div ref={ref} className="w-full p-6">
                  <div className="flex justify-end">
                    <Close
                      onClick={closeCrossSellPopUp}
                      className="h-4 w-4 shrink-0 cursor-pointer"
                    />
                  </div>
                  <div className="flex flex-col gap-4 items-center">
                    <DocumentIcon className="w-12 h-12 shrink-0" />
                    <div className="flex flex-col text-center">
                      <Header
                        langKey="text_66951de4510dd7006508cef2"
                        element="h1"
                        className="text-2xl leading-8"
                      />
                      <Text langKey="text_66951de4510dd7006508cef7" />
                    </div>
                    <Button
                      label={t('text_66951de4510dd7006508cefa')}
                      name="crossSellPopUp.show_options"
                      color="secondary"
                      variant="outline"
                      className="w-full text-off-black justify-center no-underline mt-6"
                      onClick={handleMoreInfoClick}
                    />
                  </div>
                </div>
              )
            )}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default CrossSellPopUp;
