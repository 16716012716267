import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { measureLinkClick } from '../../utils/analytics';
import Button from '../ui/Button';
import Header from '../ui/Header';
import Text from '../ui/Text';
import Close from '../../public/icons/close.svg';
import DocumentIcon from '../../public/icons/documents.svg';

function CrossSellBanner() {
  const [isClosed, setIsClosed] = useState(false);
  const { t } = useTranslation();

  const handleMoreInfoClick = () => {
    measureLinkClick('cross sell banner link');
    window.open('https://factuurfinanciering.rabobank.nl/?utm_source=AB&utm_medium=PBLC_referral&utm_campaign=topbar', '_blank');
  };

  const closeCrossSellBanner = () => {
    setIsClosed(true);
    measureLinkClick('cross sell banner close');
  };

  if (isClosed) {
    return null;
  }

  return (
    <>
      <div className="h-16 items-center gap-4 justify-center w-full py-2 bg-white z-20 align-middle border-b border-gray-medium hidden lg:flex">

        <div className="flex gap-1 items-center">
          <DocumentIcon className="h-10 w-10 shrink-0" />

          <Text
            weight="semibold"
            size="md"
            color="secondary"
            langKey="text_66951de4510dd7006508cef4"
            className="leading-6"
          />

          <Text
            weight="normal"
            size="sm"
            color="gray"
            langKey="text_66951de4510dd7006508cefc"
          />
        </div>

        <div className="flex gap-6">
          <Button
            variant="link"
            onClick={handleMoreInfoClick}
            name="more_informatie"
            label={t('text_66951de4510dd7006508ceed')}
            className="underline hover:text-primary text-base justify-center pl-4"
          />

          <Close
            onClick={closeCrossSellBanner}
            className="shrink-0 h-4 w-4 place-self-center cursor-pointer"
          />
        </div>

      </div>

      {/* mobile screen */}
      <div className="flex items-center justify-between w-full p-4 bg-white z-30 align-middle border-b border-gray-medium lg:hidden">
        <div className="flex gap-2">
          <DocumentIcon className="h-10 w-10 shrink-0 place-self-center" />
          <div className="flex flex-col">
            <Header
              langKey="text_66951de4510dd7006508cef4"
              element="h4"
            />

            <Text
              weight="light"
              size="md"
              color="black"
              langKey="cross_sell_banner_text"
            />

          </div>
        </div>
        <Close
          onClick={closeCrossSellBanner}
          className="shrink-0 place-self-start cursor-pointer"
        />
      </div>
    </>
  );
}

export default CrossSellBanner;
