import { useLocation } from 'react-router-dom';
import useNewStartFlow from '../hooks/useNewStartFlow.tsx';
import { pageMapper, restrictedPageOrder } from '../utils/constants.ts';
import { useStoreContext } from '../utils/store.tsx';

const allowedPagesAfterFinish = [
  pageMapper.offerSuccess,
  pageMapper.feedbackSuccess,
];

function AppGate({ children }) {
  const { state } = useStoreContext();
  const location = useLocation();

  const { isReady, treatment } = useNewStartFlow();
  const newStartFlow = isReady && treatment === 'on';

  const { requester, flowFinished } = state;
  const { kvkNumber } = requester;

  const { pathname } = location;
  const currLocationId = restrictedPageOrder[pathname];

  const isRestricted = Boolean(currLocationId);
  const isFinishedPage = allowedPagesAfterFinish.includes(pathname);

  if (isRestricted && !kvkNumber && !newStartFlow) {
    window.location.replace(pageMapper.home);
  }

  if (flowFinished && !isFinishedPage) {
    sessionStorage.clear();
    window.location.href = '/';
  }

  return children;
}

export default AppGate;
