import React, { type ButtonHTMLAttributes, type MouseEvent } from 'react';
import cn from 'classnames';
import { buttonClick } from '../../utils/analytics';
import { joinValues } from '../../utils/helpers';
import Loader from './Loader';

const sizes = {
  sm: 'py-2.5 px-4',
  md: 'py-[11px] px-6',
  lg: 'py-4',
  custom: '',
};

const borders = {
  base: 'border-2',
  thin: 'border',
};

type ColorConfig = {
  text: string;
  border: string;
  bg: string;
  ring: string;
  ringOffset: string;
  hover?: string;
};

// When CSS classes are selected or generated dynamically, do not use string concatination
// to combine fragments of the full class. Instead switch between the complete strings.
// https://tailwindcss.com/docs/content-configuration#dynamic-class-names
const colors = {
  base: {
    text: 'text-off-black',
    border: 'border-gray-300',
    bg: 'bg-white',
    ring: 'focus:ring-primary',
    ringOffset: 'focus:ring-offset-white',
    hover: 'hover:bg-slate-100',
  },
  primary: {
    text: 'text-primary',
    border: 'border-primary',
    bg: 'bg-primary',
    ring: 'focus:ring-primary',
    ringOffset: 'focus:ring-offset-white',
    hover: 'hover:bg-slate-100',
  },
  secondary: {
    text: 'text-secondary',
    border: 'border-secondary',
    bg: 'bg-secondary',
    ring: 'focus:ring-secondary',
    ringOffset: 'focus:ring-offset-white',
    hover: 'hover:bg-slate-100',
  },
  white: {
    text: 'text-white',
    border: 'border-white',
    bg: 'bg-white',
    ring: 'focus:ring-white',
    ringOffset: 'focus:ring-offset-secondary',
  },
  black: {
    text: 'text-off-black',
    border: 'border-off-black',
    bg: 'bg-off-black',
    ring: 'focus:ring-off-black',
    ringOffset: 'focus:ring-offset-white',
    hover: 'hover:bg-slate-100',
  },
};

export type ButtonColor = keyof typeof colors;

type ButtonColorMap = {
  [K in ButtonColor]: ColorConfig;
};

const buttonColors: ButtonColorMap = colors;

type Type =
  | 'button'
  | 'submit'
  | 'reset';

export type ButtonVariant =
  | 'base'
  | 'link'
  | 'outline';

type ButtonSize = keyof typeof sizes;

type ButtonBorder = keyof typeof borders;

type ButtonProps = {
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  label: string;
  name: string;
  disabled?: boolean;
  isLoading?: boolean;
  form?: string;
  className?: string;
  buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
  variant?: ButtonVariant;
  borderWidth?: ButtonBorder;
  size?: ButtonSize;
  type?: Type;
  color?: ButtonColor;
};

function Button({
  onClick,
  label,
  name,
  disabled = false,
  isLoading = false,
  form = '',
  className = '',
  buttonProps = {},
  variant = 'base',
  borderWidth = 'base',
  size = 'md',
  type = 'button',
  color = 'secondary',
}: ButtonProps) {
  const onClickWithAnalytics = (event: MouseEvent<HTMLButtonElement>) => {
    buttonClick(event);

    if (onClick) {
      onClick(event);
    }
  };

  const {
    text,
    border,
    bg,
    ring,
    ringOffset,
    hover,
  } = buttonColors[color];

  return (
    <button
      disabled={disabled || isLoading}
      form={form}
      type={type}
      name={name}
      aria-label={`button_${name}`}
      id={`button_${name}`}
      data-testid={`button_${name}`}
      onClick={onClickWithAnalytics}
      className={joinValues({
        base: 'flex justify-start items-center gap-4 pointer-events-auto',
        focus: 'focus:ring-2 focus:ring-offset-2 focus:outline-none',
        text: 'text-sm font-semibold leading-6',
        border: 'rounded',
        options: className,
        variant: cn(
          variant === 'base' && joinValues({
            base: 'shadow-sm text-white justify-center',
            options: cn(bg, ring, sizes[size]),
            border: 'border-2 border-transparent',
            disabled: 'disabled:bg-gray-dark',
            hover: 'hover:brightness-90 disabled:hover:brightness-100',
          }),
          variant === 'link' && joinValues({
            options: text,
            disabled: 'disabled:text-gray-dark',
          }),
          variant === 'outline' && joinValues({
            base: 'shadow-sm justify-center',
            options: cn(text, sizes[size], border, ring, ringOffset, hover),
            border: borders[borderWidth],
          }),
        ),
      })}
      {...buttonProps}
    >
      {isLoading && <Loader />}
      <span>{label}</span>
    </button>
  );
}

export default Button;
