import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { motion } from 'framer-motion';
import { transition, transitionDuration } from '../../utils/helpers';
import Text from './Text';

type ErrorMessageProps = {
  name: string;
  errorKey?: string;
  error?: string;
  className?: string;
  useLayoutId?: boolean;
};

function ErrorMessage({ name, error, errorKey, className, useLayoutId }: ErrorMessageProps) {
  const { t } = useTranslation();

  const layoutId = useLayoutId
    ? name
    : undefined;

  const translation = errorKey && t(`error.${errorKey}`);
  const message = error || translation;

  return Boolean(message) && (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { delay: transitionDuration } }}
      exit={{ opacity: 0 }}
      transition={transition}
      layoutId={layoutId}
      layout="position"
      className={cn(className, 'pl-px mt-1')}
      data-testid={`${name}_error_message`}
    >
      <Text size="sm" color="error">
        {message}
      </Text>
    </motion.div>
  );
}

export default ErrorMessage;
