import { featureFlags } from '../utils/constants';
import useSplit from './useSplit';

const useCrossSell = () => {
  const { crossSell } = featureFlags;
  const { isReady, treatment } = useSplit(crossSell);

  return {
    isCrossSellReady: isReady,
    crossSellTreatment: treatment,
  };
};

export default useCrossSell;
