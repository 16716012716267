import React, { type RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';
import cn from 'classnames';
import { buttonClick } from '../../utils/analytics';
import { pageMapper } from '../../utils/constants';
import { joinValues, transition } from '../../utils/helpers';
import ComponentLayout from '../layout/ComponentLayout';
import Divider from '../ui/Divider';
import Text from '../ui/Text';
import RabobankLogo from '../../public/icons/rabobank_logo.svg';

const component = 'footer';

type RaboButtonProps = {
  className?: string;
  onClick: () => void;
};

function RaboButton({ className, onClick }: RaboButtonProps) {
  const name = `${component}_navi_logo_button`;

  return (
    <button
      onClick={onClick}
      type="button"
      className={cn(className, 'w-fit')}
      name={name}
      id={name}
      aria-label={name}
    >
      <RabobankLogo className="h-6 fill-secondary" />
    </button>
  );
}

function Footer() {
  const parentRef = useOutletContext<RefObject<HTMLDivElement>>();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateHome = () => {
    const event = t('event.navigation.back_to_home', { component });
    buttonClick(event);

    parentRef?.current?.scroll({ top: 0, behavior: 'smooth' });
    navigate(pageMapper.home);
  };

  const items = [
    'code_of_conduct',
    'terms',
    'disclaimer',
    'privacy',
    'cookies',
  ];

  return (
    <ComponentLayout
      transition={transition}
      layoutId={component}
      className={joinValues({
        base: 'self-start w-full flex-col text-left',
        spacing: ' gap-10 py-10',
        lg: 'lg:flex-row lg:justify-center',
        xl: 'xl:justify-between',
      })}
    >
      <Divider className="lg:hidden" />
      <RaboButton
        onClick={navigateHome}
        className="lg:hidden"
      />

      <div className="flex flex-col gap-4 text-sm lg:flex-row lg:items-center">
        {items.map((item) => (
          <Text
            langKey={`${component}.${item}`}
            size="sm"
            key={item}
            linkVariant="base"
          />
        ))}
        <Text
          langKey={`${component}.copyright`}
          size="sm"
          color="custom"
          className="text-gray-light"
        />
      </div>

      <RaboButton
        className="hidden xl:block"
        onClick={navigateHome}
      />
    </ComponentLayout>
  );
}

export default Footer;
