import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { joinValues } from '../../utils/helpers';
import ComponentLayout from '../../components/layout/ComponentLayout';
import Button from '../../components/ui/Button';
import Header from '../../components/ui/Header';
import Text from '../../components/ui/Text';

function WordPartner() {
  const { t } = useTranslation();

  const component = 'contact.partner';
  const backgroundImage = 'bg-partner bg-no-repeat bg-cover';

  const goToPartners = () => {
    window.open(t('link.partners'), '_blank');
  };

  const goToWordPartner = () => {
    window.open(t('link.word_partner'), '_blank');
  };

  return (
    <>
      {/* Mobile background image */}
      <div className={classNames(backgroundImage, 'min-h-[375px] bg-[28%_60%] md:hidden')} />

      <ComponentLayout className="flex-col items-center py-10 bg-gray-background lg:bg-transparent">
        <div
          className={joinValues({
            base: 'flex gap-6 w-full items-center justify-center',
            lg: 'lg:bg-gray-background lg:rounded lg:overflow-clip',
          })}
        >
          {/* Content */}
          <div className="flex flex-col gap-4 md:basis-1/2 lg:p-14 lg:gap-8">
            <div className="flex flex-col gap-2 max-w-md lg:gap-4 lg:max-w-sm">
              <Header
                langKey="text_65b98fc42cb7b100f5ccf217"
                size="h3"
                element="h2"
              />
              <Text
                langKey="text_65b98fc42cb7b100f5ccf218"
              />
            </div>

            <div className="flex flex-col gap-4 sm:flex-row lg:gap-6 lg:mr-auto">
              <Button
                name={`${component}_get_contact`}
                label={t('text_65b98fc42cb7b100f5ccf219')}
                className="w-full whitespace-nowrap"
                color="primary"
                variant="base"
                onClick={goToWordPartner}
              />
              <Button
                name={`${component}_more_info`}
                label={t('text_65b98fc42cb7b100f5ccf21a')}
                className="w-full whitespace-nowrap"
                color="secondary"
                variant="outline"
                onClick={goToPartners}
              />
            </div>
          </div>

          {/* Desktop background image */}
          <div
            className={joinValues({
              base: 'rounded basis-1/2 h-full hidden bg-[28%_40%]',
              image: backgroundImage,
              md: 'md:block',
              lg: 'lg:bg-[40%_40%] lg:rounded-none',
              xl: 'xl:bg-[20%_40%]',
            })}
          />
        </div>
      </ComponentLayout>
    </>
  );
}

export default WordPartner;
