import type { AxiosPromise } from 'axios';
import api from '../utils/api';

const nonEligibilityReason = [
  'SBI_CODE_INVALID',
  'LEGAL_STRUCTURE_INVALID',
  'FOUNDATION_DATE_CHECK',
] as const;

export type NonEligibilityReason = typeof nonEligibilityReason[number];

export function isNonEligibilityReason(value: unknown): value is NonEligibilityReason {
  return nonEligibilityReason.some((reason) => reason === value);
}

type RequesterEligibilityResponse = {
  eligible: boolean;
  message?: string;
  errors?: string[];
};

type RequesterEligibilityProps = {
  kvkNumber: string;
  utmTags?: string;
};

export default function requesterEligibility({
  kvkNumber,
  utmTags,
}: RequesterEligibilityProps): AxiosPromise<RequesterEligibilityResponse> {
  const path = 'dls-pbl-api/v3/requester-eligibility';
  const body = { kvkNumber };
  const utmHeader = { 'X-UTM-Tags': utmTags };

  return api.post({ path, body, headers: utmHeader });
}

type LoanAmountProps = {
  amount: number;
};

export function setLoanAmount({ amount }: LoanAmountProps): AxiosPromise {
  const path = 'dls-pbl-api/v3/loan-amount';
  const body = { loanAmount: amount };

  return api.post({ path, body });
}

type ExpectedExpenses = {
  hasExpenses: boolean;
  knowsAmount: boolean;
  expenses?: number;
};

export function setExpectedExpenses({
  hasExpenses,
  knowsAmount,
  expenses,
}: ExpectedExpenses): AxiosPromise {
  const path = 'dls-pbl-api/v3/expected-expenses';

  const body = {
    hasExpenses,
    knowsAmount,
    amount: expenses,
  };

  return api.post({ path, body });
}

export type JourneyBlockState =
  | 'PENDING'
  | 'SUCCESS'
  | 'FAILED';

export type JourneyBlocks =
  | 'LOAN_GOAL'
  | 'REQUESTER_ELIGIBILITY'
  | 'REQUESTED_DETAILS'
  | 'EXPECTED_EXPENSES'
  | 'TRANSACTION_ANALYSIS'
  | 'SCORECARD_RESULT'
  | 'AFFORDABILITY_RESULT'
  | 'OFFERS_AVAILABLE'
  | 'OFFERS_REQUESTED'
  | 'OFFER_SELECTED';

type CaseStateResponse = {
  journeyBlocks: { [K in JourneyBlocks]: JourneyBlockState };
  offering: 'PBL';
  origin: string;
};

export function getCaseState(): AxiosPromise<CaseStateResponse> {
  const path = 'dls-pbl-api/v3/case-state';

  return api.get({ path });
}

export type Goal =
  | 'DEBTORS'
  | 'GROWTH_AND_EXPANSION'
  | 'MARKETING'
  | 'INVENTORY'
  | 'REFINANCE'
  | 'STOCK'
  | 'WORKING_CAPITAL'
  | 'VEHICLES'
  | 'PROPERTY'
  | 'RENOVATION'
  | 'OTHER';

type LoanGoalProps = {
  loanGoal: Goal;
  description?: string;
};

export function setLoanGoal({ loanGoal, description }: LoanGoalProps): AxiosPromise {
  const path = 'dls-pbl-api/v3/loan-goal';

  const body = {
    loanGoal,
    description,
  };

  return api.post({ path, body });
}

export type AccountMatch =
  | 'MATCH'
  | 'NO_MATCH'
  | 'COULD_NOT_MATCH'
  | 'NOT_CHECKED';

export type AccountDetails = {
  // accountId: string;
  // name: string;
  // nature: string;
  // accountName: string;
  // accountNumber: string;
  matchState: AccountMatch;
  iban: string;
  clientName: string;
};

export type GetTransactionsResponse = {
  accounts: AccountDetails[];
  sub: string;
  bank: BankCode;
};

type GetTransactionsProps = {
  connectionId: string;
  kvkNumber: string;
};

export function getTransactions({
  connectionId,
  kvkNumber,
}: GetTransactionsProps): AxiosPromise<GetTransactionsResponse> {
  const path = 'dls-pbl-api/v3/transactions/saltedge/fetch-transactions';

  const body = {
    kvkNumber,
    connectionId,
  };

  return api.post<GetTransactionsResponse>({ path, body });
}

export type BankCode =
  | 'RABOBANK'
  | 'ING'
  | 'ABN'
  | 'KNAB'
  | 'ASN'
  | 'SNS'
  | 'REGIOBANK'
  | 'TRIODOS'
  | 'BUNQ';

export const bankCodeToBankName: { [key in BankCode]: string } = {
  RABOBANK: 'Rabobank',
  ING: 'ING Bank',
  ABN: 'ABN Amro',
  KNAB: 'Knab',
  ASN: 'ASN Bank',
  SNS: 'SNS Bank',
  REGIOBANK: 'Regiobank',
  TRIODOS: 'Triodos',
  BUNQ: 'Bunq Bank',
};

export type ConnectionDetail = {
  connectionId: string;
  consentId?: string;
  needReconsent?: boolean;
  consentExpiresAt?: string;
  bankName: BankCode;
  accounts: AccountDetails[];
};

type AccountDetailsResponse = {
  customerId: string;
  connectionDetails: ConnectionDetail[];
};

type FetchAccountsProps = {
  caseId: string;
};

export function getBankAccounts({
  caseId,
}: FetchAccountsProps): AxiosPromise<AccountDetailsResponse> {
  const path = `dls-pbl-api/v3/fetch-accounts?caseId=${caseId}`;

  return api.get({ path });
}

type ReconnectResponse = {
  expiresAt: string;
  connectUrl: string;
};

type ReconnectProps = {
  returnUrl: string;
  connectionId: string;
};

export function reconnect({
  returnUrl,
  connectionId,
}: ReconnectProps): AxiosPromise<ReconnectResponse> {
  const path = 'dls-pbl-api/v3/transaction/psd2/reconnect';

  const body = {
    returnUrl,
    connectionId,
  };

  return api.post({ path, body });
}

export const productTypes = [
  'PBL',
  'PBC',
] as const;

export type ProductType = typeof productTypes[number];

export type Offer = {
  id: string;
  productType: ProductType;
  term: string;
  loanAmount: number;
  totalInterestCost: number;
  linearMonthlyAmortization: number;
  clientRate: number;
  creditProvision: number;
};

type GetOfferResponse = {
  scoreIsRed: boolean;
  offers: Offer[];
  isEligibleForDls: boolean;
};

export function getOffers(): AxiosPromise<GetOfferResponse> {
  const path = 'dls-pbl-api/v3/offers';

  return api.get({ path });
}

type FeedbackRequestV1 = {
  rating: number;
  referenceSource: string;
  suggestions?: string;
};

export function feedback({
  rating,
  referenceSource,
  suggestions,
}: FeedbackRequestV1): AxiosPromise {
  const path = 'dls-pbl-api/v3/feedback';

  const body = {
    rating,
    referenceSource,
    suggestions,
  };

  return api.post({ path, body });
}
