/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { type ElementType, type ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import cn from 'classnames';
import { pageMapper } from '../../utils/constants';
import { isKeyOf } from '../../utils/predicates';
import InfoIcon from '../../public/icons/info.svg';
import Link, { type Variants as LinkVariants } from './Link';
import Tooltip from './Tooltip';

const sizes = {
  xs: 'text-xs',
  sm: 'text-sm',
  md: 'text-base',
  lg: 'text-lg',
  xl: 'text-xl',
};

const colors = {
  primary: 'text-primary',
  secondary: 'text-secondary',
  white: 'text-white',
  black: 'text-off-black',
  gray: 'text-gray-light',
  custom: 'custom',
  success: 'text-success',
  green: 'text-green-dark',
  error: 'text-error',
};

const weights = {
  inherit: 'font-inherit-weight',
  normal: 'font-normal',
  bold: 'font-bold',
  light: 'font-light',
  semibold: 'font-semibold',
};

const variants = {
  pill: 'flex items-center justify-center px-4 py-2 bg-gray-medium rounded-full whitespace-nowrap',
};

export type TextVariant = keyof typeof variants;

export type TextSize = keyof typeof sizes;

export type TextColor = keyof typeof colors;

export type TextWeight = keyof typeof weights;

type TooltipItemProps = {
  children?: React.ReactNode;
};

function TooltipItem({ children }: TooltipItemProps) {
  const Icon = <InfoIcon className="w-5 mb-0.5" />;

  return (
    <Tooltip content={Icon} className="inline-flex align-middle">
      <Text
        langKey={`tooltip.${children}`}
        size="xs"
        color="white"
      />
    </Tooltip>
  );
}

type LinkItemProps = {
  children?: string[];
  variant?: LinkVariants;
};

function LinkItem({ children, variant = 'underline' }: LinkItemProps) {
  const { t } = useTranslation();
  const [value] = children ?? [];

  const [key, label] = value?.split(';') ?? '';
  const item = key?.toLowerCase().replace(/ /g, '_');

  const link = t(`link.${item}`);
  const isExternal = link.includes('http');

  const page = isKeyOf(pageMapper, link)
    ? pageMapper[link]
    : '';

  const href = isExternal ? link : page;

  return (
    <Link
      className="font-inherit-weight text-inherit-size leading-[inherit]"
      href={href}
      variant={variant}
    >
      {label ?? key}
    </Link>
  );
}

type TextProps = {
  element?: ElementType;
  name?: string;
  langKey?: string;
  className?: string;
  color?: TextColor;
  size?: TextSize;
  weight?: TextWeight;
  linkVariant?: LinkVariants;
  variant?: TextVariant;
  values?: Record<string, string>;
  children?: ReactNode;
};

function Text({
  className,
  langKey,
  values,
  linkVariant,
  variant,
  children,
  element,
  name,
  color = 'black',
  size = 'md',
  weight = 'light',
}: TextProps) {
  const options = cn(
    colors[color],
    className,
    weights[weight],
    sizes[size],
    variant && variants[variant],
  );

  if (children) {
    const Element = element ?? 'p';

    return (
      <Element className={options}>
        {children}
      </Element>
    );
  }

  return (
    <Trans
      data-testid={name}
      i18nKey={langKey}
      parent={element ?? 'div'}
      shouldUnescape
      values={values}
      className={cn('whitespace-pre-line', options)}
      components={{
        tooltip: <TooltipItem />,
        ol: <ol className="list-decimal pl-6" />,
        ul: <ul className="list-disc pl-6" />,
        li: <li />,
        a: <LinkItem variant={linkVariant} />,
        external: <LinkItem variant={linkVariant} />,
      }}
    />
  );
}

export default Text;
