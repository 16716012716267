export const BV_ABBREVIATION = 'b.v.';

export const pageMapper = {
  home: '/',
  zakelijkeLening: '/zakelijke-lening',
  kortlopendeLening: '/kortlopende-lening',
  zakelijkKrediet: '/zakelijk-krediet',
  kortlopendKrediet: '/kortlopend-krediet',
  faq: '/veelgestelde-vragen',
  aboutYou: '/jouw-aanvraag',
  extraExpenses: '/jouw-aanvraag/extra-uitgaven',
  contact: '/contact',
  cookies: '/cookies',
  transactions: '/jouw-aanvraag/transacties-uploaden',
  getTransactions: '/jouw-aanvraag/transacties-verzamelen',
  getOffers: '/jouw-aanvraag/get-offers',
  offer: '/jouw-aanvraag/persoonlijk-aanbod',
  offerSuccess: '/jouw-aanvraag/volgende-stappen',
  requestFailed: '/jouw-aanvraag/request-failed',
  feedbackSuccess: '/jouw-aanvraag/feedback-success',
  transactionSuccess: '/jouw-aanvraag/transaction-success',
  reconsent: '/reconsent',
  getReconsent: '/reconsent/get-consent',
  redirection: '/redirection',
  kennisbankOndernemenDebiteuren: '/kennisbank/ondernemen/debiteurenbeheer',
  kennisbankOndernemenEcommerce: '/kennisbank/ondernemen/jouw-shop-klaar-voor-het-najaar',
  kennisbankOndernemenVoorraadBeheer: '/kennisbank/ondernemen/voorraad-beheer',
  kennisbankFinancieleBegrippenWerkkapitaal: '/kennisbank/financiele-begrippen/werkkapitaal',
  kennisbankFinancieleBegrippenLiquiditeitVerbeteren: '/kennisbank/financiele-begrippen/liquiditeit-verbeteren',
  kennisbankFinancieleBegrippenRoodStaan: '/kennisbank/financiele-begrippen/rood-staan-of-niet',
  kennisbankFinancieleBegrippenWatIsBtw: '/kennisbank/financiele-begrippen/wat-is-btw',
  kennisbankFinancieringsmogelijkhedenMkbFinanciering: '/kennisbank/financieringsmogelijkheden/mkb-financieringen',
  kennisbankFinancieringsmogelijkhedenVoorraadFinancieren: '/kennisbank/financieringsmogelijkheden/voorraad-financieren',
  kennisbankFinancieringsmogelijkhedenWerkkapitaalfinanciering: '/kennisbank/financieringsmogelijkheden/werkkapitaalfinanciering',
} as const;

export type PageValue = typeof pageMapper[keyof typeof pageMapper];

export const restrictedPageOrder: Partial<Record<PageValue, number>> = {
  [pageMapper.requestFailed]: -1,
  [pageMapper.offerSuccess]: -1,
  [pageMapper.feedbackSuccess]: -1,
  [pageMapper.aboutYou]: 1,
  [pageMapper.extraExpenses]: 2,
  [pageMapper.transactions]: 3,
  [pageMapper.getTransactions]: 3,
  [pageMapper.getOffers]: 4,
  [pageMapper.offer]: 5,
};

export const utmSources = {
  partner: 'partner',
};

export const storageKeys = {
  jwt: 'jwt',
  correlationId: 'correlation_id',
  partnerId: 'partner_id',
  navigationHeight: 'navigation_height',
  caseId: 'case_id',
  progressBarWidth: 'bar_width',
  progressBarPosition: 'bar_position',
  hasVisited: 'has_visited',
  userId: 'user_id',
  isPartner: 'is_partner',
};

export const featureFlags = {
  customBanner: 'pbl_banner_maintenance',
  newStartFlow: 'pbl_new_start_flow',
  busyBanner: 'pbl_busy_banner',
  auth: 'pbl_auth',
  crossSell: 'pbl_cross_sell',
};
